import React, { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';
import { useAuth } from '@/context/AuthContext';

const UnreadMessagesCount = ({ setUnreadMessagesCount }) => {
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      fetchUnreadCount();

      const channel = supabase
        .channel('unread_messages')
        .on('postgres_changes', { 
          event: 'INSERT', 
          schema: 'public', 
          table: 'messages',
          filter: `sender_id=neq.${user.id}`
        }, () => {
          fetchUnreadCount();
        })
        .on('postgres_changes', { 
          event: 'UPDATE', 
          schema: 'public', 
          table: 'messages' 
        }, () => {
          fetchUnreadCount();
        })
        .subscribe();

      return () => {
        supabase.removeChannel(channel);
      };
    }
  }, [user]);

  const fetchUnreadCount = async () => {
    if (!user) return;

    const { count, error } = await supabase
      .from('messages')
      .select('*', { count: 'exact', head: true })
      .eq('is_read', false)
      .neq('sender_id', user.id);

    if (error) {
      console.error('Error fetching unread messages count:', error);
    } else {
      setUnreadMessagesCount(count || 0);
    }
  };

  return null; // Acest component nu renderează nimic
};

export default UnreadMessagesCount;
