'use client'

import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import {  User, MessageCircle, Settings, LogOut, Users, BookOpenCheck, Hammer, Bell, Search, Briefcase, Shield, FileText, PlusSquare } from 'lucide-react'
import { motion } from 'framer-motion'
import { useAuth } from '@/context/AuthContext'
import { supabase } from '@/lib/supabase'
import UnreadMessagesCount from '@/components/notifications/unreadMessagesCount'
import MobileNavigation from './navigation/mobileNav'
import Image from 'next/image';

const navbarClasses = "fixed left-0 top-0 h-full bg-gray-900 z-50 overflow-y-auto overflow-x-hidden navbar hidden md:block shadow-xl"

export default function Navbar() {
  const { user, signOut, loading } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const router = useRouter()
  const mobileMenuRef = useRef(null)
  const [unreadCount, setUnreadCount] = useState(0)
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0)

  const navItems = useMemo(() => {
    return [
      {name: 'Cere oferta', href: '/new-post', icon: PlusSquare },
      { name: 'Lucrări Active', href: '/lucrari-actuale', icon: Briefcase },
      { name: 'Servicii', href: '/servicii', icon: Search },
      { name: 'Blog', href: '/blog', icon: FileText },
    ];
  }, []);

  const authItems = useMemo(() => {
    if (!user) return [];
    
    return [
      { 
        name: 'Dashboard', 
        icon: User, 
        onClick: () => {
          const dashboardRoute = user.user_metadata.role === 'client' ? '/dashboard/client' : '/dashboard/worker';
          router.push(dashboardRoute);
        }
      },
      {
        name: 'Messages',
        icon: MessageCircle,
        onClick: () => router.push('/messages'),
        badge: unreadMessagesCount > 0 ? unreadMessagesCount : null
      },
      {
        name: 'Notifications',
        icon: Bell,
        onClick: () => router.push('/notifications'),
        badge: unreadCount > 0 ? unreadCount : null
      },
      
      { name: 'Profil', icon: Settings, onClick: () => router.push('/profil') },
      { name: 'Logout', icon: LogOut, onClick: async () => {
        try {
          await signOut()
          router.push('/')
        } catch (error) {
          console.error('Error signing out:', error)
        }
      }},
    ]
  }, [user, router, signOut, unreadCount, unreadMessagesCount])

  const renderNavItems = useCallback((isMobile = false) => (
    <div className={isMobile ? "mb-8" : "space-y-2"}>
      {navItems.map((item) => (
        <NavItem 
          key={item.name} 
          item={item} 
          isMobile={isMobile} 
          isHovered={isHovered}
          onClick={() => setIsOpen(false)} 
        />
      ))}
    </div>
  ), [navItems, setIsOpen, isHovered])

  const renderAuthItems = useCallback((isMobile = false) => {
    if (loading) {
      return (
        <div className="flex flex-col space-y-4">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="w-9 h-9 bg-white bg-opacity-20 rounded-full"></div>
          ))}
        </div>
      )
    }

    const items = (
      <div className={`flex flex-col space-y-2 ${isMobile ? '' : 'mt-auto'}`}>
        {authItems && authItems.map((item, index) => (
          <AuthItem 
            key={index} 
            item={item} 
            isHovered={isHovered}
            onClick={() => setIsOpen(false)} 
            isMobile={isMobile}
          />
        ))}
      </div>
    )

    if (!user) {
      return (
        <div className={`flex flex-col space-y-2 ${isMobile ? '' : 'mt-auto'}`}>
          {items}
          <AuthLink 
            href="/login" 
            icon={User} 
            text="Log In" 
            isMobile={isMobile} 
            isHovered={isHovered}
            onClick={() => setIsOpen(false)} 
          />
          <AuthLink 
            href="/register/worker" 
            icon={Users} 
            text="Inregistrare Firme" 
            isMobile={isMobile} 
            isHovered={isHovered}
            onClick={() => setIsOpen(false)} 
          />
        </div>
      )
    }

    return items
  }, [user, authItems, loading, setIsOpen, isHovered])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const checkUnreadNotifications = useCallback(async () => {
    if (!user) return;

    try {
      const { data, error } = await supabase
        .from('notifications')
        .select('id')
        .eq('user_id', user.id)
        .eq('is_read', false);

      if (error) throw error;
      
      setUnreadCount(data?.length || 0);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      checkUnreadNotifications();

      const interval = setInterval(checkUnreadNotifications, 30000);

      const subscription = supabase
        .channel('notifications')
        .on('postgres_changes', {
          event: 'INSERT',
          schema: 'public',
          table: 'notifications',
          filter: `user_id=eq.${user.id}`
        }, () => {
          checkUnreadNotifications();
        })
        .on('postgres_changes', {
          event: 'UPDATE',
          schema: 'public',
          table: 'notifications',
          filter: `user_id=eq.${user.id}`
        }, () => {
          checkUnreadNotifications();
        })
        .subscribe();

      return () => {
        clearInterval(interval);
        subscription.unsubscribe();
      };
    }
  }, [user, checkUnreadNotifications]);

  return (
    <>
      {user && <UnreadMessagesCount setUnreadMessagesCount={setUnreadMessagesCount} />}
      <motion.nav 
        className={navbarClasses}
        initial={false}
        animate={{ width: isHovered ? '16rem' : '4.3rem' }}
        transition={{ duration: 0.3 }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="flex flex-col h-full p-4 w-full">
          <Link 
            href="/" 
            className="text-2xl font-bold text-white hover:text-teal-200 transition-colors duration-200 mb-8 block"
          >
            <div className="flex items-center h-10 relative">
              <div className="w-10 h-10   bg-opacity-10 flex items-center justify-center flex-shrink-0 overflow-hidden">
                <Image
                  src="/logo.png"
                  alt="Meserias Local Logo"
                  width={60}
                  height={60}
                  className="object-contain p-1"
                  priority
                />
              </div>
              {isHovered && (
                <motion.span
                  className="absolute left-12 whitespace-nowrap"
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -10 }}
                  transition={{ duration: 0.2 }}
                >
                  Meserias Local
                </motion.span>
              )}
            </div>
          </Link>
          <div className="flex-grow">
            {renderNavItems()}
          </div>
          <div className="mt-auto">
            {renderAuthItems()}
          </div>
        </div>
      </motion.nav>

      <MobileNavigation 
        user={user}
        unreadMessagesCount={unreadMessagesCount}
        unreadCount={unreadCount}
        navItems={navItems}
        authItems={authItems}
        signOut={signOut}
      />
    </>
  )
}

const NavItem = React.memo(({ item, isMobile, onClick, isHovered }) => (
  <Link
    href={item.href}
    className={`group flex items-center text-white hover:text-gray-200 transition-colors duration-200 h-10 w-full`}
    onClick={onClick}
  >
    <div className="w-9 h-9 rounded-full bg-gray-800 group-hover:bg-gray-700 flex items-center justify-center flex-shrink-0 transition-colors duration-200">
      <item.icon className="h-6 w-6" />
    </div>
    {(isMobile || isHovered) && (
      <motion.span
        className="ml-4 whitespace-nowrap"
        initial={{ opacity: 0, x: -10 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -10 }}
        transition={{ duration: 0.2 }}
      >
        {item.name}
      </motion.span>
    )}
  </Link>
))

const AuthItem = React.memo(({ item, isHovered, onClick, isMobile }) => (
  <button
    className="group flex items-center text-white hover:text-gray-200 transition-colors duration-200 h-10 w-full relative"
    onClick={() => {
      item.onClick();
      onClick();
    }}
  >
    <div className="w-9 h-9 rounded-full bg-gray-800 group-hover:bg-gray-700 flex items-center justify-center flex-shrink-0 relative transition-colors duration-200">
      <item.icon className="h-6 w-6" />
      {item.badge && (
        <span className="absolute -top-1 -right-1 bg-gray-200 text-gray-900 text-xs rounded-full h-5 w-5 flex items-center justify-center font-medium">
          {item.badge}
        </span>
      )}
    </div>
    {(isMobile || isHovered) && (
      <motion.span
        className="ml-4 whitespace-nowrap"
        initial={{ opacity: 0, x: -10 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -10 }}
        transition={{ duration: 0.2 }}
      >
        {item.name}
      </motion.span>
    )}
  </button>
));

const AuthLink = React.memo(({ href, icon: Icon, text, onClick, isMobile, isHovered }) => (
  <Link 
    href={href} 
    className={`group flex items-center text-white hover:text-gray-200 transition-colors duration-200 h-10 w-full`} 
    onClick={onClick}
  >
    <div className="w-9 h-9 rounded-full bg-gray-800 group-hover:bg-gray-700 flex items-center justify-center flex-shrink-0 transition-colors duration-200">
      <Icon className="h-6 w-6" />
    </div>
    {(isMobile || isHovered) && (
      <motion.span
        className="ml-4 whitespace-nowrap"
        initial={{ opacity: 0, x: -10 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -10 }}
        transition={{ duration: 0.2 }}
      >
        {text}
      </motion.span>
    )}
  </Link>
));